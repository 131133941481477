































import { Component, Prop, Vue } from 'vue-property-decorator'
import { searchAddressByDistrict, searchAddressByAmphoe, searchAddressByProvince, searchAddressByZipcode } from 'thai-address-database'

@Component({})
export default class InputAddress extends Vue {
    @Prop()
    value: any

    @Prop()
    type: any

    @Prop()
    rules: any

    @Prop()
    menuProps: any

    textTemplate = [
        'district',
        'amphoe',
        'province',
        'zipcode'
    ]

    items: any[] = []

    clickSelectItem (item: any): void {
        const address = {
            subDistrict: '',
            district: '',
            province: '',
            postcode: ''
        }
        if (item.amphoe) {
            address.subDistrict = item?.district || ''
            address.district = item?.amphoe || ''
            address.province = item?.province || ''
            address.postcode = item.zipcode || ''
        } else if (item.subDistrict) {
            address.subDistrict = address?.subDistrict || ''
            address.district = address?.district || ''
            address.province = address?.province || ''
            address.postcode = address.postcode || ''
        }
        this.$emit('select', address)
    }

    get innerValue (): any {
        return this.value
    }

    set innerValue (val: any) {
        this.$emit('input', val)
    }

    get innerType (): any {
        switch (this.type) {
            case 'sub-district': return 'district'
            case 'district': return 'amphoe'
            case 'province': return 'province'
            case 'postcode': return 'zipcode'
            default: return null
        }
    }

    search (keyword: string): void {
        if (keyword) {
            switch (this.innerType) {
            case 'district': this.items = searchAddressByDistrict(keyword) || []
                break
            case 'amphoe': this.items = searchAddressByAmphoe(keyword) || []
                break
            case 'province': this.items = searchAddressByProvince(keyword) || []
                break
            case 'zipcode': this.items = searchAddressByZipcode(keyword) || []
                break
            default: this.items = []
            }
        }
    }
}
