






































































































































































































































































/* eslint-disable @typescript-eslint/no-explicit-any */

import { Component, Prop, Vue } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
    computed: {
    ...mapState({
      storeInfo: (state: any) => state.Store.storeInfo,
      storeSetting: (state: any) => state.Store.storeSetting
    })
  }
})
export default class TaxInvoice extends Vue {
  storeInfo!: any

  storeSetting!: any

  formatDateTime!: any

  @Prop() order!: any

  @Prop() isPreview!: boolean

  get webUrl (): any {
    return this.storeSetting?.web_url || ''
  }

  sumAmount = (items: Array<any>): any => items.reduce((sum: number, d) => sum + d.amount, 0)

  priceStepPricing = (items: Array<any>): any => items.reduce((sum: number, d) => sum + d.price, 0)

  showFullPriceFormat = (number: number): any => ((number).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')

  calPriceBeforeVat = (price: number): number => (price * 100) / 107

  showProductName = (text: string): any => {
    const a = text.split('-')
    if (a[0].length > 25) {
      return [`${a[0].substring(0, 24) }...`, a[1]]
    }
    return [a[0], a[a.length - 1]]
  }
}
